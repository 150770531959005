import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Col, Container, Row } from "react-bootstrap"
import SiteLogoSrc from "../../content/assets/logo-inverted.svg"

export const Footer = ({ title }) => {
  return (
    <FooterContainer>
      <Container>
        <Row className="py-3">
          <Col>
            <SiteLogo src={SiteLogoSrc} alt="Logo du site" />
            <span>{title}</span>
          </Col>
          <Col className="d-none d-md-block" />
          <Col>
            <ul>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/legal">Mentions légales</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: ${({ theme }) => theme.main.important};
  &,
  & a {
    color: ${({ theme }) => theme.main.inactive};
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`

const SiteLogo = styled.img`
  height: 50px;
  & ~ span {
    color: white;
    font-family: Eczar, serif;
    font-weight: 800;
  }
`
