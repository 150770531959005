import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { GlobalStyles } from "../globalStyles"
import { SiteNavbar } from "./siteNavbar"
import HomePageCover from "../../content/assets/home-page-cover.jpg"
import { DarkOverlay } from "./DarkOverlay"
import { Footer } from "./Footer"

const Layout = props => {
  const {
    site: {
      siteMetadata: { title, description },
    },
    siteLogo,
  } = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      siteLogo: file(
        dir: { regex: "/assets$/" }
        name: { eq: "logo-inverted" }
      ) {
        publicURL
      }
    }
  `)
  const { location, children } = props
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <Header>
        <BluredCoverContainer />
        <CoverContainer />
        <DarkOverlay />
        <Container>
          <Row>
            <HeadingCol lg={8} md={10} className="mx-auto">
              <SiteLogo src={siteLogo.publicURL} alt="Logo du site" />
              <h1>{title}</h1>
              <p>{description}</p>
            </HeadingCol>
          </Row>
        </Container>
      </Header>
    )
  } else {
    header = <NoCoverPlaceholder />
  }
  return (
    <>
      <GlobalStyles />
      <header>
        <SiteNavbar title={title} />
        {header}
      </header>
      <ContentContainer>{children}</ContentContainer>
      <Footer title={title} />
    </>
  )
}

const Header = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  color: white;
`

const BluredCoverContainer = styled.div`
  position: absolute;
  margin: -10px -20px;
  height: 110%;
  width: 110%;
  background: gray url(${HomePageCover}) no-repeat scroll center center;
  background-size: cover;
  filter: blur(8px);
`

const CoverContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(${HomePageCover}) no-repeat scroll center center;
`

const HeadingCol = styled(Col)`
  text-align: center;

  > {
    h1 {
      font-size: 50px;
      font-weight: bold;
      margin-top: 0;
    }

    .span {
      font-size: 24px;
      font-weight: 300;
      line-height: 1.1;
      display: block;
      margin: 10px 0 0;
      @include sans-serif-font;
    }
  }

  padding: 100px 0 50px;
  @media only screen and (min-width: 992px) {
    padding: 100px 0;
  }
`

const ContentContainer = styled(Container)`
  padding-bottom: 104px;
`

const SiteLogo = styled.img`
  height: 100px;
`

const NoCoverPlaceholder = styled.div`
  background: ${({ theme }) => theme.main.secondary};
  height: 67px;
  margin-bottom: 50px;
`

export default Layout
