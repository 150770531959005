import React, { Component } from "react"
import { Link } from "gatsby"
import { Container, Nav, Navbar } from "react-bootstrap"
import styled from "styled-components"
import { darken, rgba } from "polished"
import * as PropTypes from "prop-types"
import SiteLogoSrc from "../../content/assets/logo-inverted.svg"

export class SiteNavbar extends Component {
  constructor(props) {
    super(props)
    this.state = { fixed: false, hidden: true }
    this.previousYOffset = 0
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    let { fixed, hidden } = this.state
    const currentTop = window.pageYOffset

    if (currentTop > this.previousYOffset) {
      //if scrolling down...
      if (currentTop > 67) fixed = true
      hidden = true
    } else {
      //if scrolling up...
      if (currentTop > 0 && fixed) {
        hidden = false
      } else {
        hidden = true
        fixed = false
      }
    }
    this.setState({ fixed, hidden })
    this.previousYOffset = currentTop
  }

  render() {
    const { title } = this.props
    const { fixed, hidden } = this.state
    return (
      <PaddedNavbar
        expand="lg"
        className={`${fixed ? "fixed" : ""} ${hidden ? "" : "visible"}`}
      >
        <Container>
          <Brand to="/">
            <SiteLogo src={SiteLogoSrc} alt="Logo du site" height="20px" />
            {title}
          </Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav navbar className="ml-auto">
              <Nav.Link as={Link} to="/contact">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </PaddedNavbar>
    )
  }
}

SiteNavbar.propTypes = { title: PropTypes.string.isRequired }

const PaddedNavbar = styled(Navbar)`
  &.navbar.navbar-light {
    background: white;
    border-bottom: 1px solid gray;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    @media only screen and (min-width: 992px) {
      font-size: medium;
      background: transparent;
      border-bottom: 1px solid transparent;
      transition: background 0.2s;
      transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;

      .navbar-nav {
        > .nav-link {
          font-size: 12px;
          font-weight: 800;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }

      &:not(.fixed) .navbar-nav {
        > .nav-link {
          color: white;

          &:focus,
          &:hover {
            color: ${rgba("white", 0.8)};
          }
        }
      }

      &.fixed {
        position: fixed;
        top: -67px;
        background: ${rgba("white", 0.9)};
        transition: transform 0.2s;
        border-bottom: 1px solid ${darken(0.05, "white")};
      }

      &.visible {
        transform: translate3d(0, 100%, 0);
      }
    }
  }
`

const Brand = styled(props => <Navbar.Brand {...props} as={Link} />)`
  display: flex;
  flex-direction: row;
  align-items: center;

  .navbar-light &.navbar-brand {
    font-weight: 800;
    @media only screen and (min-width: 992px) {
      padding: 10px 20px;
    }
  }

  .navbar-light:not(.fixed) &.navbar-brand {
    @media only screen and (min-width: 992px) {
      color: white;
      &:focus,
      &:hover {
        color: ${rgba("white", 0.8)};
      }
    }
  }
`

const SiteLogo = styled.img`
  height: 30px;
  margin-right: 4px;
`
